import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(-52, -45) scale(.6)">
          <line
            x1="17.392035"
            y1="0"
            x2="-17.392035"
            y2="0"
            transform="translate(151 123.785918)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="0"
            y1="-26.214082"
            x2="0"
            y2="26.214082"
            transform="translate(132.607965 150)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="-17.392035"
            y1="0"
            x2="17.392035"
            y2="0"
            transform="translate(151 150)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="-17.392035"
            y1="0"
            x2="17.392035"
            y2="0"
            transform="translate(151 176.214082)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
