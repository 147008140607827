import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <g transform="translate(-52, -45) scale(.6)">
          <line
            x1="17.392035"
            y1="0"
            x2="-17.392035"
            y2="0"
            transform="translate(150 123.785918)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="0"
            y1="-26.214082"
            x2="0"
            y2="26.214082"
            transform="translate(132.607965 150)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="-17.392035"
            y1="0"
            x2="17.392035"
            y2="0"
            transform="translate(150 150)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
          <line
            x1="-17.392035"
            y1="0"
            x2="17.392035"
            y2="0"
            transform="translate(150 176.214082)"
            fill="none"
            stroke="currentColor"
            strokeWidth="7"
            strokeLinecap="round"
          />
        </g>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
